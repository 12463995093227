import { gql } from '@apollo/client';
import algoliasearch from 'algoliasearch/lite';

import { getFiltrableFields } from '@/lib/graphql/api';
import { isEmptyObject, isNumber } from '@/lib/utils';
import { listOfCardsServerTransformer } from '..';
import APClient, { generateError } from '../../client';
import { cardBlog } from '../../partials';
import { postCardTransformer } from './post';

import type { FiltrableFields } from '@/components/algolia/list-of-products/types';
import type {
  TypeCardBannerGraphQL,
  TypeFlexibleContextGraphQL,
  TypeProductAlgolia,
} from '@/lib/graphql/types';
import type { TypePostTypeWordpress } from '@/types';
import type { InstantSearchServerState } from 'react-instantsearch';
import type { TypeCategory } from './types';

type TypeAlgoliaState = {
  title: string;
  path: string;
  categoryAtribute?: string;
  filters?: string;
};

const mutatePropsWithAlgoliaState = async ({
  title,
  categoryAtribute,
  filters,
  path,
  customFilters,
  hideFilters,
}: TypeAlgoliaState & {
  customFilters?: Array<{ title: string }>;
  hideFilters?: Array<{ title: string }>;
}) => {
  const flexible: { [key: string]: any } = {};

  const {
    data: { filtrableFields },
  } = await getFiltrableFields();

  let filtersFieldsCustom = filtrableFields ?? [];

  if (Array.isArray(customFilters)) {
    const sortOrder = customFilters.map((i) => i.title);

    filtersFieldsCustom = filtersFieldsCustom
      .filter((i) => customFilters.find((c) => c.title === i.title))
      .sort((a, b) => sortOrder.indexOf(a.title) - sortOrder.indexOf(b.title));
  }

  if (hideFilters) {
    filtersFieldsCustom = filtersFieldsCustom.filter((i) =>
      hideFilters.find((c) => !c.title.startsWith(i.title)),
    );
  }

  flexible.filtrableFields = filtersFieldsCustom;
  flexible.title = title;
  flexible.categoryAtribute = categoryAtribute ?? null;
  flexible.filters = filters ?? null;
  flexible.url = path;

  // TODO remove code below when instansearch bug is fixed -> https://github.com/algolia/instantsearch/issues/5650
  // or we migrate to NextJS 14

  const possiblePage = flexible.url.split('/').filter(Boolean).at(-1);
  let page = 0;

  if (isNumber(possiblePage)) {
    page = possiblePage - 1;
  }

  const algoliaClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID ?? '',
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '',
  );

  const algoliaIndex = algoliaClient.initIndex(
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX_NAME ?? '',
  );

  const results = await algoliaIndex.search('', {
    hitsPerPage: 24,
    filters: flexible.filters,
    facets: flexible.filtrableFields.map((field: FiltrableFields[number]) =>
      field?.type === 'RangeSlider'
        ? (field?.attribute ?? '')
        : (field?.seo ?? ''),
    ),
    page: page,
  });

  const customServerState: InstantSearchServerState = {
    initialResults: {
      [process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX_NAME ?? '']: {
        state: {
          hitsPerPage: 24,
          facets: [],
          index: algoliaIndex.indexName,
          filters: flexible.filters,
          disjunctiveFacets: flexible.filtrableFields.map(
            (field: FiltrableFields[number]) =>
              field?.type === 'RangeSlider'
                ? (field?.attribute ?? '')
                : (field?.seo ?? ''),
          ),
          disjunctiveFacetsRefinements: (() => {
            const obj: { [facet: string]: string[] } = {};

            flexible.filtrableFields.forEach(
              (field: FiltrableFields[number]) => {
                obj[field?.seo ?? 'key-not-found'] = [];
              },
            );

            return obj;
          })(),
          page: page,
        },
        results: [results],
      },
    },
  };

  // const serverState = await getServerState(
  //   <Category
  //     filtrableFields={flexible.filtrableFields}
  //     title={flexible.title}
  //     categoryAtribute={flexible.categoryAtribute}
  //     filters={flexible.filters}
  //     url={flexible.url}
  //   />,
  //   { renderToString },
  // );

  flexible.serverState = customServerState;
  flexible.notFound = results?.hits?.length === 0;

  return flexible;
};

// TODO: Mirar porque tener en una función getServerState provoca el warning:
// warn  - ./node_modules/react-instantsearch-hooks-server/dist/es/getServerState.js
// @see https://github.com/algolia/instantsearch/issues/5555
/**
 * Transform the flexible content of a page
 *
 * @param flexible Wordpress flexible content
 * @param type Wordpress post type
 * @param path Path of the page
 * @param typeFlexible Type of flexible content
 * @returns The flexible content with the transformed data
 */
export const flexibleContentTransformer = async (
  flexible: any[],
  type: TypePostTypeWordpress,
  path: string,
  typeFlexible: TypeFlexibleContextGraphQL = 'Contenidoflexible',
  staticProps: any = {},
) => {
  const promises = flexible
    .filter(Boolean)
    .filter((flexible) => !isEmptyObject(flexible))
    .map(async ({ fieldGroupName, ...props }) => {
      switch (fieldGroupName) {
        case `${type}_${typeFlexible}_Flexible_Blog`:
          const [blog, page] = path.split('/').filter(Boolean);

          if (blog === 'blog' && page && !isNumber(page)) break;

          const pageNumber = Number(page || 1);

          if (pageNumber <= 0) {
            staticProps.notFound = true;
            break;
          }

          const { data, errors } = await APClient.query({
            query: gql`
              query blogPagination {
                posts(where: { offsetPagination: { size: 12, offset: ${(pageNumber - 1) * 12} } }) {
                  pageInfo {
                    offsetPagination {
                        total
                    }
                  }
                  edges {
                    node {
                      ${cardBlog}
                      categories {
                        edges {
                          node {
                            name
                            uri
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
          });

          if (errors) {
            errors.forEach((error: any) =>
              // eslint-disable-next-line no-console
              console.log(
                generateError(error.message, error.locations, error.path),
              ),
            );
          }

          props.posts = data?.posts?.edges.map(({ node }: any) =>
            postCardTransformer(node),
          );
          props.totalPages =
            Math.ceil(data?.posts?.pageInfo?.offsetPagination?.total / 12) || 0;
          props.current = pageNumber;

          if (props.posts?.length === 0) staticProps.notFound = true;
        case `${type}_${typeFlexible}_Flexible_GridProductBySeller`:
          if (!props.seller?.length) return { fieldGroupName, ...props };

          const { shopName } = props.seller[0].sellersMirakl;

          props = {
            ...props,
            ...(await mutatePropsWithAlgoliaState({
              path,
              title: shopName,
              categoryAtribute: 'hierarchicalCategories.lvl0',
              filters: `seller:"${shopName}"`,
              hideFilters: [{ title: 'Vendido por:' }],
              ...(Boolean(props.customFilters?.active) && {
                customFilters: props.customFilters.filters,
              }),
            })),
          };

          break;
        case `${type}_${typeFlexible}_Flexible_GridProductByBrand`:
          if (!props.brand?.length) return { fieldGroupName, ...props };

          const { title: titleBrand, slug: slugBrand } = props.brand[0];

          props = {
            ...props,
            ...(await mutatePropsWithAlgoliaState({
              path,
              title: titleBrand,
              categoryAtribute: 'hierarchicalCategories.lvl0',
              filters: `marca:"${slugBrand}"`,
              hideFilters: [{ title: 'Marca' }],
              ...(Boolean(props.customFilters?.active) && {
                customFilters: props.customFilters.filters,
              }),
            })),
          };

          break;
        case `${type}_${typeFlexible}_Flexible_GridProductByCategory`:
          if (!props.categories?.length) return { fieldGroupName, ...props };

          const generateHierarchicalLevel = ({
            slug,
            ancestors,
          }: TypeCategory): string => {
            const categories = [slug];

            ancestors?.edges?.forEach(({ node: { slug } }) =>
              categories.push(slug),
            );

            return categories.reverse().join(' > ');
          };
          const generateFilters = (categories: TypeCategory[]) =>
            categories
              .map(
                ({ slug, ancestors }) =>
                  `hierarchicalCategories.lvl${
                    ancestors?.edges?.length ?? 0
                  }:"${generateHierarchicalLevel({ slug, ancestors })}"`,
              )
              .join(' OR ');
          const filters = generateFilters(props.categories);
          const level =
            (Number(filters?.split(':')?.at(0)?.split('lvl')[1]) || 0) + 1;

          props = {
            ...props,
            ...(await mutatePropsWithAlgoliaState({
              path,
              title: props?.title ?? '',
              filters,
              categoryAtribute: `hierarchicalCategories.lvl${level}`,
              ...(Boolean(props.customFilters?.active) &&
                props.customFilters?.filters && {
                  customFilters: props.customFilters.filters,
                }),
            })),
          };

          break;
        case `${type}_${typeFlexible}_Flexible_BloqueProductosMarca`:
          const {
            brands,
            bannersList,
          }: {
            brands: { slug: string }[];
            bannersList: {
              banner: { id: string; cpt_banner: TypeCardBannerGraphQL };
              position: number;
            }[];
          } = props;
          let facadeBuilder = '';

          if (!Array.isArray(brands)) return { fieldGroupName, ...props };

          for (let i = 0; i < brands.length; ++i) {
            if (!brands[i]?.slug) continue;

            facadeBuilder += `"marca:${brands[i]?.slug}"`;

            if (i < brands.length - 1) {
              facadeBuilder += ',';
            }
          }

          await fetch(
            `https://${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID}-dsn.algolia.net/1/indexes/${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX_NAME}/query`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-Algolia-API-Key':
                  process.env.NEXT_ALGOLIA_SEARCH_API_KEY ?? '',
                'X-Algolia-Application-Id':
                  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID ?? '',
              },
              body: `{
              "params":"facetFilters=${encodeURIComponent(
                `[[${facadeBuilder}]]`,
              )}&hitsPerPage=1000"
            }`,
            },
          )
            .then((response) => {
              if (!response.ok) {
                return;
              }
              return response.json();
            })
            .then(
              (result: {
                nbHits: number;
                hits:
                  | TypeProductAlgolia[]
                  | (typeof bannersList)[0]['banner'][];
              }) => {
                if (result.nbHits === 0) {
                  return;
                }

                bannersList
                  ?.filter(Boolean)
                  .forEach((b) => result.hits.splice(b.position, 0, b.banner));

                props.products = result.hits;
                props.backgroundColor = props.backgroundColorString;
                props.path = path;

                // object cleanup
                delete props.backgroundColorString;
                delete props.bannersList;
                delete props.brands;

                // set ready to render
                props.isReady = true;
              },
            )
            .catch();

          break;
        case `${type}_${typeFlexible}_Flexible_BloqueProductos`:
          props.productos = listOfCardsServerTransformer(props.productos, {
            itemListId: props.itemListId,
            itemListName: props.itemListName,
          });
          break;
      }

      return { fieldGroupName, ...props };
    });

  return Promise.all(promises);
};

import { routes } from '@/lib/routes';
import { getBreadcrumbsByPost, getRealURL } from '@/lib/utils';
import { flexibleContentServerTransformer } from '..';

import type { TypeCardBlogProps } from '@/molecules/card-blog';
import type { TypePostHeaderRightProps } from '@/molecules/post-header-right';
import type { TypePostProps } from '@/pages/post';

export const postCardTransformer = (
  post: any,
): Omit<TypeCardBlogProps, 'excerpt'> & { excerpt?: string | null } => {
  const image = post?.featuredImage?.node
    ? {
        src: post?.featuredImage?.node?.sourceUrl as string,
        alt: post?.featuredImage?.node?.altText as string,
      }
    : null;

  return {
    image,
    date: post.date as string,
    readingTime: post?.cptBlog?.readingTime || null,
    title: post.title as string,
    excerpt: (post.excerpt as string) || null,
    href: routes.blog.post(
      post?.primaryCategory?.path
        ?.replace('/category', '')
        ?.replaceAll('/', '') || '',
      post.slug,
    ),
    badge: post?.primaryCategory?.name || null,
  };
};

export const postTransformer = async (
  staticProps: { [key: string]: any },
  path: string,
): Promise<void> => {
  if (!staticProps.props.post?.title) {
    staticProps.notFound = true;
    return;
  }

  const { post, bcGeneralSettings } = staticProps?.props || {};
  const { featuredImage, primaryCategory, cptBlog, author, ...others } = post;

  const image = featuredImage?.node
    ? {
        src: featuredImage.node?.sourceUrl as string,
        alt: featuredImage.node?.altText as string,
      }
    : null;

  const category = {
    slug:
      primaryCategory?.path?.replace('/category', '')?.replaceAll('/', '') ||
      '',
    name: primaryCategory?.name || '',
  };

  const cross: TypePostHeaderRightProps = {
    title: bcGeneralSettings?.ajustesGenerales?.crossPost?.title,
    description: bcGeneralSettings?.ajustesGenerales?.crossPost?.description,
    image: bcGeneralSettings?.ajustesGenerales?.crossPost?.image?.sourceUrl
      ? {
          src: bcGeneralSettings?.ajustesGenerales?.crossPost?.image
            ?.sourceUrl as string,
          alt: (bcGeneralSettings?.ajustesGenerales?.crossPost?.image
            ?.altText || '') as string,
        }
      : null,
    link: bcGeneralSettings?.ajustesGenerales?.crossPost?.link,
  };

  const recommendedPosts: TypePostProps['recommendedPosts'] = {
    title: bcGeneralSettings?.ajustesGenerales?.recommendedPosts
      ?.title as string,
    posts:
      bcGeneralSettings?.ajustesGenerales?.recommendedPosts?.posts
        ?.filter(Boolean)
        .map((post: any) => {
          const image = post?.featuredImage?.node
            ? {
                src: post?.featuredImage?.node?.sourceUrl as string,
                alt: post?.featuredImage?.node?.altText as string,
              }
            : null;

          return {
            image,
            date: post.date as string,
            readingTime: post?.cptBlog?.readingTime || null,
            title: post.title as string,
            excerpt: (post.excerpt as string) || null,
            href: routes.blog.post(category.slug, post.slug),
          };
        }) || null,
  };

  if (cptBlog?.postContent?.flexible) {
    const {
      postContent: { flexible },
    } = cptBlog;

    cptBlog.postContent.flexible = await flexibleContentServerTransformer(
      flexible,
      'Post',
      path,
      'Cptblog_PostContent',
      staticProps,
    );
  }

  staticProps.props.post = {
    ...others,
    uri: getRealURL(path),
    image,
    category: {
      label: category.name,
      href: routes.blog.category.index(category.slug),
    },
    breadcrumbs: getBreadcrumbsByPost({
      post: {
        url: path,
        name: others.title,
      },
      category: {
        url: routes.blog.category.index(category.slug),
        name: category.name,
      },
    }),
    cross,
    readingTime: (cptBlog?.readingTime as number) || null,
    author: (author?.node?.name as string) || null,
    recommendedPosts,
    postContent: cptBlog.postContent,
  };

  delete staticProps.props.bcGeneralSettings.ajustesGenerales.crossPost;
  delete staticProps.props.bcGeneralSettings.ajustesGenerales.recommendedPosts;
  delete staticProps.props.post.featuredImage;
  delete staticProps.props.post.primaryCategory;
  delete staticProps.props.post.cptBlog;
};

export const filtrableFields = `
  filtrableFields {
    title
    seo
    type
    attribute
    values {
      title
      seo
    }
  }
`;

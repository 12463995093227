import { gql } from '@apollo/client';

import APClient from '../client';
import { filtrableFields } from '../partials';

import type { FiltrableFields } from '@/components/algolia/list-of-products/types';

export const getFiltrableFields = (): Promise<{
  data: { filtrableFields: FiltrableFields | undefined | null };
}> =>
  APClient.query({
    query: gql`
      query FiltersQuery {
        ${filtrableFields}
      }
    `,
  });

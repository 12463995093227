import { routes } from '@/lib/routes';
import { INITIAL_BREADCRUMBS } from './utils';

import type { FiltrableFields } from '@/components/algolia/list-of-products/types';
import type { BreadCrumbs } from './types';

/**
 *
 * @param path NextRouter.asPath
 * @returns
 */
export const getBreadcrumbsByUrl = (path: string): BreadCrumbs => {
  const breadcrumbs = INITIAL_BREADCRUMBS();

  let urlBuilder = '/';

  const urlRegex = new RegExp(/\//, 'g');
  const nameRegex = new RegExp(/\?|#/, 'g');

  const urlSplitted = path.split(urlRegex).slice(1);

  for (const url of urlSplitted) {
    // we don't allow numbers as breadcrumbs
    if (!isNaN(Number(url))) continue;

    // remove marcas and vendedores
    if (url === 'marcas' || url === 'vendedores') continue;

    const breadCrumbName = url.split(nameRegex)[0]?.replaceAll('-', ' ');

    // check for null names
    if (!breadCrumbName) continue;

    urlBuilder = `${urlBuilder}${url}/`;

    breadcrumbs.push({
      name: breadCrumbName,
      url: urlBuilder,
    });
  }

  return breadcrumbs;
};

export const getBreadcrumbsByPrimaryCategory = ({
  primary,
  categories,
  product,
}: {
  primary: any;
  categories: any[];
  product: {
    name: string;
    url: string;
  };
}): BreadCrumbs => {
  const breadcrumbs = INITIAL_BREADCRUMBS();

  const primaryCategorySplitted: string[] =
    primary?.split('/').slice(1).filter(Boolean) ?? [];

  let urlBuilder = '/';

  primaryCategorySplitted.forEach((substring) => {
    let categorySlug = '';
    let categoryName = '';

    // Check for every primary category substring if match with a product category
    const category = categories.find(
      (x: { node: { name: string; slug: string } }) =>
        x.node.slug === substring,
    );

    // If yes we use that for the breadcrumb so it´s more likely to contain punctuations
    // If not we use the primary category substring as fallback
    if (category && category.node) {
      categorySlug = category.node.slug;
      categoryName = category.node.name;
    } else {
      categorySlug = substring;
      categoryName = substring.replace(/\-/g, ' ');
    }

    urlBuilder = `${urlBuilder}${categorySlug}/`;

    breadcrumbs.push({
      name: categoryName,
      url: urlBuilder,
    });
  });

  breadcrumbs.push({ ...product });

  return breadcrumbs;
};

export const getBreadcrumbsByCategory = ({
  categories,
  params,
  filtrableFields,
}: {
  categories: {
    name: string;
    slug: string;
    ancestors?: {
      edges: Array<{
        node: {
          name: string;
          slug: string;
        };
      }>;
    };
  };
  params: Array<string>;
  filtrableFields: FiltrableFields;
}): BreadCrumbs => {
  const breadcrumbs = INITIAL_BREADCRUMBS();
  let urlBuilder = '/';

  let flatCategories: Array<{ name: string; slug: string }> = [];

  if (categories.ancestors) {
    flatCategories = categories.ancestors.edges.map(({ node: category }) => ({
      name: category.name,
      slug: category.slug,
    }));
  }

  flatCategories.push({
    name: categories.name,
    slug: categories.slug,
  });

  for (const param of params) {
    const category = flatCategories.find((cat) => cat.slug === param);

    if (category) {
      urlBuilder = urlBuilder.concat(param, '/');
      breadcrumbs.push({
        name: category.name,
        url: urlBuilder,
      });
      continue;
    }

    for (const filtrableField of filtrableFields) {
      if (!param.match(new RegExp(`${filtrableField.seo}-`, 'gm'))) continue;

      const attribute = param.replace(`${filtrableField.seo}-`, '');

      const filtrableFieldValue = filtrableField.values?.find(
        (value) => value.seo === attribute,
      );

      if (!filtrableFieldValue) continue;

      urlBuilder = urlBuilder.concat(param, '/');
      breadcrumbs.push({
        name: filtrableFieldValue.title,
        url: urlBuilder,
      });
    }
  }

  return breadcrumbs;
};

export const getBreadcrumbsByPost = ({
  category,
  post,
}: {
  category: {
    name: string;
    url: string;
  };
  post: {
    name: string;
    url: string;
  };
}): BreadCrumbs => {
  const breadcrumbs = INITIAL_BREADCRUMBS();

  breadcrumbs.push({
    name: 'Blog',
    url: routes.blog.index,
  });
  breadcrumbs.push(category);
  breadcrumbs.push(post);

  return breadcrumbs;
};

export const getBreadcrumbsByCategoryPost = ({
  url,
  name,
}: {
  url: string;
  name: string;
}): BreadCrumbs => {
  const breadcrumbs = INITIAL_BREADCRUMBS();

  breadcrumbs.push({
    name: 'Blog',
    url: routes.blog.index,
  });

  breadcrumbs.push({
    name,
    url,
  });

  return breadcrumbs;
};

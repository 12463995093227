import { removeLastSlash, toArray } from '@/lib/utils';
import { productCardWordpressTransformer } from '../card-product';
import { postCardTransformer } from './post';

type Card = { [key: string]: any; uri: string };
type QueryParams = { [key: string]: string };

/**
 * Transform a list of cards to a list of cards with the correct type
 * @param {Card | Card[]} cards Card or array of cards to transform
 * @param {QueryParams} queryParams Query params to add to the uri
 * @returns Array of transformed cards
 */
export const listOfCardsTransformer = (
  cards: Card | Card[],
  queryParams?: QueryParams,
) =>
  toArray(cards)
    .filter(Boolean)
    .filter(
      (item: any) =>
        item?.status === 'publish' || 'cpt_banner' in item || 'cptBlog' in item,
    )
    .map((card: any) => {
      if ('uri' in card && queryParams) {
        const query = new URLSearchParams();

        Object.entries(queryParams).forEach(([key, value]) => {
          if (typeof value === 'string') query.set(key, value);
        });

        if ([...query].length > 0)
          card.uri = `${removeLastSlash(card.uri)}?${query.toString()}`;
      }

      if ('status' in card)
        return {
          postType: 'product',
          ...productCardWordpressTransformer(card),
        };

      if ('cptBlog' in card)
        return { postType: 'blog', ...postCardTransformer(card) };

      if ('cpt_banner' in card) return { postType: 'banner', ...card };
    })
    .filter(Boolean);

import { imagePartial } from '../common';

export const cardBlog = `
  slug
  featuredImage {
    node ${imagePartial}
  }
  title
  excerpt
  date
  cptBlog {
    readingTime
  }
  primaryCategory {
    name
    path
  }
`;
